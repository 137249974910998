import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Container } from "../../Container";
import "./StaffProfiles.scss";

export const StaffProfiles = ({ staffProfiles, title }) => {
  return (
    <Container>
      <section className="staff-profiles layout">
        <div className="staff-profiles__wrapper">
          {title && <h2 className="heading">{title}</h2>}
          <div className="profiles">
            {Object.keys(staffProfiles).length > 0 &&
              staffProfiles?.map((profile, index) => {
                return (
                  <div className="profile" key={index}>
                    <div className="image">
                      <div className="aspect">
                        <ImagePass src={profile.image} />
                      </div>
                    </div>
                    <div className="content">
                      <h3 className="name">{profile.name}</h3>
                      <RenderContent content={profile.position} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </Container>
  );
};
