import React from "react";
import "./TwoColumn.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Container } from "../../Container";

export const TwoColumn = ({ column }) => {
  return (
    <Container>
      <section className="two-column layout">
        <div className="two-column__wrapper">
          {Object.keys(column).length > 0 &&
            column?.map((item, index) => {
              return (
                <div className="col" key={index}>
                  <div className="image-container">
                    <ImagePass src={item.image} />
                  </div>
                  <RenderContent content={item.content} />
                </div>
              );
            })}
        </div>
      </section>
    </Container>
  );
};
