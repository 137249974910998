import React from "react";
import "./ConstrainedColourBlock.scss";
import RenderContent from "../../RenderContent";
import { Container } from "../../Container";

export const ConstrainedColourBlock = ({ leftColumn, rightColumn }) => {
  return (
    <Container>
      <section className="constrained-colour-block layout">
        <div className="constrained-colour-block__wrapper">
          <div className="col">
            <RenderContent content={leftColumn} />
          </div>
          <div className="col">
            <RenderContent content={rightColumn} />
          </div>
        </div>
      </section>
    </Container>
  );
};
