import React, { useEffect, useState } from "react";
import "./RelatedPass.scss";
import { Carousel } from "../../Carousel";
import { PostListItem } from "../../Blog/PostListItem";
import { decodeEntities } from "../../../utils/helpers";
import GatsbyLink from "../../GatsbyLink";
import { Container } from "../../Container";
import { useIsClient } from "../../../hooks";

export const RelatedPass = ({ data, title, action, postfilter }) => {
  const { allWordpressPost, allWordpressWpProjects } = data;
  const postPass = allWordpressWpProjects
    ? allWordpressWpProjects
    : allWordpressPost;
  const { edges: posts } = postPass;

  const swiperSettings = {
    id: "related-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    pagination: false,
    navigation: true,
    breakpoints: {
      700: {
        slidesPerView: "auto",
      },
    },
  };
  const [filteredPosts, setFilteredPosts] = useState([]);
  const isClient = useIsClient();

  useEffect(() => {
    if (!postfilter) {
      // remove the current post (not sure if this is going to work)
      let currentLocation;

      if (isClient) {
        currentLocation = window.location.pathname;
      } else {
        currentLocation = "";
      }
      const result = posts.filter((post) => post.node.path !== currentLocation);
      setFilteredPosts(result.slice(0, 5));
    } else {
      const filterItemsArray = postfilter.map(function (obj) {
        return obj.wordpress_id;
      });

      // filters through all projects and returns the ones which match the filterItemsArray
      const filteredResults = posts.filter((post) =>
        filterItemsArray.includes(post.node.wordpress_id)
      );
      setFilteredPosts(filteredResults);
    }
  }, [isClient, postfilter, posts]);

  return (
    <Container>
      <section className="related-pass layout">
        <div className="related-pass__wrapper">
          <h2 className="title">{decodeEntities(title)}</h2>
          <Carousel swiperSettings={swiperSettings}>
            {Object.keys(filteredPosts).length > 0 &&
              filteredPosts?.map((item, index) => {
                return <PostListItem key={index} data={item.node} />;
              })}
          </Carousel>
        </div>
        {action && (
          <div className="related-pass-button">
            <GatsbyLink to={action.url} className="button--blue">
              {action.title}
            </GatsbyLink>
          </div>
        )}
      </section>
    </Container>
  );
};
