import React, { useEffect } from "react";
import "./ThreeColumn.scss";
import RenderContent from "../../RenderContent";
import AOS from "aos";
import "aos/dist/aos.css";

export const ThreeColumn = ({ columns }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="three-column layout">
      <div className="three-column__wrapper">
        {Object.keys(columns).length > 0 &&
          columns?.map((item, index) => {
            return (
              <div
                className="col"
                key={index}
                data-aos="fade-up"
                data-aos-duration={`${index} * 800`}
              >
                <RenderContent content={item.content} />
              </div>
            );
          })}
      </div>
    </section>
  );
};
