import React from "react";
import "./SimpleContent.scss";
import { Container } from "../../Container";
import RenderContent from "../../RenderContent";

export const SimpleContent = ({ content }) => {
  return (
    <Container>
      <section className="simple-content layout">
        <div className="simple-content-wrapper">
          <RenderContent content={content} />
        </div>
      </section>
    </Container>
  );
};
