import React from "react";
import "./PostListItem.scss";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { decodeEntities } from "../../../utils/helpers";

export const PostListItem = ({
  data: { path, title, excerpt, featured_media },
  date,
}) => {
  return (
    <GatsbyLink to={path} className="post-list-item">
      <div className="image-container">
        <ImagePass src={featured_media} />
      </div>
      <div className="content">
        <h3>{decodeEntities(title)}</h3>
        {excerpt && <RenderContent content={excerpt} />}
        <span className="arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 5L19 12L12 19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
    </GatsbyLink>
  );
};
