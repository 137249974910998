/* eslint-disable no-console */
import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import * as FieldResolver from "./fields";
import { googleTestKeys, pushDataLayerEvent, simpleUniqueId } from "./gf-utils";
import "./GravityForm.scss";

/**
 * Gravity Form component with Invisible Recaptcha Support.
 * @example
 * <GravityForm
 *  formData={data}
 *  recaptchaEnabled={false}
 *  customClass="non-recaptcha-form"
 * />
 * @param {object} formData - The Gravity Form data retrieved from GraphQL.
 * @param {string} customClass - Overrides the class placed on the form element, otherwise default is set to "gf-form".
 * @param {string} successMessage - Custom message on successful form submit.
 * @param {string} errorMessage - Custom message on a failed form submission.
 * @param {boolean} displayTitle - Toggle display of the form title from GravityForms GraphQL.
 * @param {boolean} displayDescription - Toggle display of the form description from GravityForms GraphQL.
 * @param {boolean} recaptchaEnabled - Enable/disable recaptcha. ReCaptcha Key should be in .env or passed via siteKey prop.
 * @param {string} customTitle - Provide an optional custom title. Replaces title set in WordPress GravityForms options.
 * @param {string} customDescription - Provide an optional custom description. Replaces description set in WordPress GravityForms options.
 * @param {string} siteKey - If recaptchaEnabled, use the siteKey. Site key is analogous to Google's Recaptcha Site Key. MUST BE AN INVISIBLE RECAPTCHA KEY.
 * @param {string} submissionUrl - Custom URL you can use for submitting your form to.
 * @param {string} dataLayerEvent - provide a dataLayerEvent.
 */
export const GravityForm = ({
  formData,
  customClass = "",
  successMessage = "",
  errorMessage = "",
  displayTitle = true,
  displayDescription = true,
  recaptchaEnabled = true,
  customTitle,
  customDescription,
  siteKey = "",
  submissionUrl,
  dataLayerEvent,
}) => {
  const {
    formFields,
    title,
    description,
    formId,
    apiURL = "",
    confirmations,
  } = formData;

  /**
   * Get our confirmation message from provided formData.
   * We are only concerned with the DEFAULT confirmation message set in GravityForms.
   * If you need to use an additional, pass this via the successMessage prop.
   * @param {string} confirmationMessage
   */
  let confirmationMessage;
  if (confirmations) {
    confirmationMessage =
      confirmations?.filter((msg) => msg.isDefault)?.pop()?.message || "";
  }
  /**
   * Recaptcha should only be enabled when form is hovered over.
   */
  const [isFormHovered, setIsFormHovered] = useState(false);
  const formRef = useRef(null);

  // necessary ref for our recaptcha component
  const recaptchaRef = useRef(null);

  /**
   * Form states on submission.
   * Error: display an error message.
   * Success: display success/confirmation message.
   * Loading: display loading state on submission.
   * */
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Simple helper to set form states in one line.
   * @param {boolean} loading state to toggle loading component
   * @param {boolean} error state to toggle error component
   * @param {boolean} success state to toggle success component
   */
  function setFormStates(loading, error, success) {
    setIsLoading(loading);
    setIsError(error);
    setIsSuccess(success);
  }

  // Submit our form data
  async function handleSubmit(event) {
    event.preventDefault();
    setFormStates(true, false, false);
    const formData = new FormData(event.target);
    if (recaptchaEnabled) {
      try {
        const token = await recaptchaRef.current.executeAsync();
        // immediately reset our token for additional form submission.
        recaptchaRef.current.reset();
        if (token) {
          if (submissionUrl) {
            formSubmit(formData, submissionUrl);
          } else {
            formSubmit(formData);
          }
        }
      } catch (err) {
        setFormStates(false, true, false);
        throw new Error(err);
      }
    } else {
      if (submissionUrl) {
        formSubmit(formData, submissionUrl);
      } else {
        formSubmit(formData);
      }
    }
  }

  /**
   * Handles our form submission provided submi
   * @param {object} values - Form values in our setFormValues state.
   * @param {string} submitUrl - Endpoint used for POST
   */
  function formSubmit(values, submitUrl = "") {
    // submission url will be of the form https://localhost/wp-json/gf/v2/forms/1/submissions
    let formSubmitUrl = submitUrl || `${apiURL}/submissions`;

    fetch(formSubmitUrl, {
      method: "POST",
      body: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          // Push event to dataLayer.
          if (typeof dataLayerEvent == "string" && dataLayerEvent) {
            pushDataLayerEvent(dataLayerEvent, location);
          }
          return res.json();
        } else {
          throw new Error(
            `Did not receive a status response of 200. Received: ${res?.status}`
          );
        }
      })
      .then((data) => {
        setFormStates(false, false, true);
        return data;
      })
      .catch((err) => {
        console.error(err);
        setFormStates(false, true, false);
      });
  }

  // Usage of custom titles or custom descriptions
  const titleToDisplay = customTitle || title;
  const descriptionToDisplay = customDescription || description;

  const mouseEnter = useCallback(() => {
    if (!isFormHovered) {
      setIsFormHovered(true);
    }
  }, []);
  useEffect(() => {
    if (formRef.current) {
      const formElement = formRef.current;
      formElement.addEventListener("mouseenter", mouseEnter);
      return () => {
        if (formElement) {
          formElement.removeEventListener("mouseenter", mouseEnter);
        }
      };
    }
  }, [mouseEnter]);
  return (
    <>
      {title && displayTitle && (
        <h2 className="gf-form--title">{titleToDisplay}</h2>
      )}
      {description && displayDescription && (
        <div className="gf-form--description--wrapper">
          <p className="gf-form--description">{descriptionToDisplay}</p>
        </div>
      )}
      <form
        id={`gravity-form--${formId}`}
        className={`${customClass ? ` ${customClass}` : "gf-form"}`}
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        ref={formRef}
      >
        <div className="form-inner">
          {formFields ? <Fields fields={formFields} /> : null}
        </div>
        {recaptchaEnabled ? (
          <div className="captcha-wrapper">
            <button className="button--blue" type="submit">
              {!isLoading ? `Send` : `Sending...`}
            </button>
            {isFormHovered ? (
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                className="invisible-recaptcha"
                sitekey={process.env.GATSBY_GRAVITY_FORMS_RECAPTCHA || siteKey}
              />
            ) : null}
          </div>
        ) : (
          <button type="submit">
            {!isLoading ? `Submit` : `Submitting..`}
          </button>
        )}
      </form>
      {isSuccess && (
        <div className="message message--success">
          {successMessage ? successMessage : confirmationMessage}
        </div>
      )}
      {isError && <div className="message message--error">{errorMessage}</div>}
    </>
  );
};

GravityForm.propTypes = {
  formData: PropTypes.shape({
    formFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    formId: PropTypes.number.isRequired,
    apiURL: PropTypes.string.isRequired,
  }).isRequired,
  customClass: PropTypes.string,
  successMessage: PropTypes.string,
  confirmationMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  displayTitle: PropTypes.bool,
  recaptchaEnabled: PropTypes.bool,
  submissionUrl: PropTypes.string,
  siteKey: PropTypes.string,
  dataLayerEvent: PropTypes.string,
};

GravityForm.defaultProps = {
  formData: {
    formFields: [
      {
        label: "Example Input",
        type: "text",
        id: 100,
        placeholder: "This is an example text input field",
      },
    ],
    title: "Example title",
    formId: 1,
    apiURL: "https://localhost/wp-json/gf/v2/forms/1/",
  },
  confirmationMessage: `Thank you for your message! We will get back to you as soon as possible.`,
  errorMessage: `An error occurred in submitting your message. Please refresh and try
  again!`,
  customTitle: "",
  customDescription: "",
  displayTitle: true,
  displayDescription: false,
  recaptchaEnabled: true,
  siteKey: googleTestKeys?.site || "",
};

const Fields = ({ fields }) => {
  if (fields?.length === 0) return null;
  /**
   * Clone our FieldResolver to allow for lowercase field definitions.
   * This will make it independent of named exports.
   **/
  const LowercasedFieldResolver = Object.fromEntries(
    Object.entries(FieldResolver).map(([k, v]) => [k.toLowerCase(), v])
  );
  // Map through our fields to find a match
  return fields?.map((field, index) => {
    const [fieldId] = useState(() => simpleUniqueId(`${field?.type}-`));
    const Field = LowercasedFieldResolver[field?.type];
    return (
      <Field
        key={`field-${field?.type}-${index}`}
        field={field}
        fieldId={fieldId}
      />
    );
  });
};

Fields.propTypes = {
  fields: PropTypes.array.isRequired,
};
