import React from "react";
import "./ServiceCards.scss";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import { RenderShortcode } from "../../RenderShortcode";
import { decodeEntities } from "../../../utils/helpers";
import { Carousel } from "../../Carousel";
import { Container } from "../../Container";

const Card = ({ content, index }) => {
  const cardThemes = [
    "quaternary",
    "secondary",
    "tertiary",
    "primary",
    "secondary",
    "quaternary",
    "primary",
    "tertiary",
    "quaternary",
    "secondary",
    "tertiary",
    "primary",
    "secondary",
    "quaternary",
    "primary",
    "tertiary",
  ];
  return (
    <a
      href={content.link.url}
      target={content.link.target}
      className="card"
      style={{
        "--card-theme": `var(--brand-${cardThemes[index]})`,
      }}
    >
      <div className="card-content">
        <h3>{decodeEntities(content.title)}</h3>
        <RenderShortcode content={content.content} />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="card-content__arrow"
        >
          <path
            d="M5 12H19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 5L19 12L12 19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="card-image">
        <ImagePass src={content.image} />
      </div>
    </a>
  );
};

export const ServiceCards = ({ title, cards, button }) => {
  const swiperSettings = {
    id: "service-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: false,
    navigation: true,
  };

  return (
    <Container>
      <section className="service-cards layout">
        <div className="service-cards__wrapper">
          <h2 className="heading">{title}</h2>
          {/* Grid is shown for desktop */}
          <div className="card-grid">
            {Object.keys(cards).length > 0 &&
              cards?.map((card, index) => {
                return <Card content={card} index={index} key={index} />;
              })}
          </div>
          {/* Carousel shown to mobile */}
          <Carousel swiperSettings={swiperSettings}>
            {Object.keys(cards).length > 0 &&
              cards?.map((card, index) => {
                return <Card content={card} index={index} key={index} />;
              })}
          </Carousel>
          {button && button.url && (
            <a
              href={button.url}
              className="button--blue service-cards__more"
              target={button.target}
            >
              {button.title}
            </a>
          )}
        </div>
      </section>
    </Container>
  );
};
