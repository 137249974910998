import React from "react";
import "./ConstrainedColourImageBlock.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import { Container } from "../../Container";
export const ConstrainedColourImageBlock = ({ content, image }) => {
  return (
    <Container>
      <section className="constrained-colour-image layout">
        <div className="constrained-colour-image--wrapper">
          <div className="image">
            <ImagePass src={image} />
          </div>

          <div className="content">
            <RenderContent content={content} />
          </div>
        </div>
      </section>
    </Container>
  );
};
