import React from "react";
import "./ContactBlock.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import placeholderImage from "./default-img.jpg";
import { Container } from "../../Container";

export const ContactBlock = ({
  image,
  imagePosition,
  content,
  squareAccent = "lg-gr_sm-bl",
}) => {
  // having to put the defaults here instead of inside default props because ACF returns null or an empty string.
  // So react still thinks something is there... lame
  const imageDefault = {
    source_url: placeholderImage,
  };
  const contentDefault =
    '<h2>We’re here for you.</h2>\n<p>Find out more about our end-to-end surveying, mapping, geospatial and sub-surface services. Learn how we’ll tailor our expertise and capabilities to ensure you extract maximum value from your project.</p>\n<p><a class="button--red" href="/contact/">Contact us</a></p>\n';
  return (
    <Container>
      <section className={`contact-block layout ${squareAccent}`}>
        <div className="contact-block__wrapper">
          <div className="col--text">
            <RenderContent content={content || contentDefault} />
          </div>
          <div
            className="col--image"
            style={{
              "--image-position": `${imagePosition}`,
            }}
          >
            <ImagePass src={image || imageDefault} />
          </div>
        </div>
      </section>
    </Container>
  );
};
