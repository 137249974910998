import React from "react";
import "./FullWidthColourImageBlock.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import { Container } from "../../Container";

const ContentType = ({ data }) => {
  switch (data.contentType) {
    case "text":
      return (
        <div className="col col--text">
          <RenderContent content={data.content} />
        </div>
      );

    case "image":
      return (
        <div className="col col--image">
          <div
            className="image-container"
            style={{
              "--image-position": `${data.imagePosition}`,
            }}
          >
            <ImagePass src={data.image} />
          </div>
        </div>
      );

    default:
      return <p>This type of content does not exist</p>;
  }
};

export const FullWidthColourImageBlock = ({ rows, theme }) => {
  return (
    <Container>
      <section
        className="full-width-color-block layout"
        style={{
          "--fullWidth-theme": `var(--brand-${theme})`,
        }}
      >
        <div className="full-width-color-block__wrapper">
          {Object.keys(rows).length > 0 &&
            rows?.map((col, index) => {
              return <ContentType data={col} key={index} />;
            })}
        </div>
      </section>
    </Container>
  );
};
