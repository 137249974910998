import React from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import { Carousel } from "../../Carousel";
import { RenderShortcode } from "../../RenderShortcode";
import placeholderImage from "./default-img.jpg";
import projectHeader from "./project-header.jpg";

export const Header = ({
  title,
  content,
  carousel,
  homepage,
  theme = "secondary",
  template,
}) => {
  const swiperSettings = {
    id: "header-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: false,
    navigation: false,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  };

  // setting the default values for the project template, and the blog template
  // its not pretty
  const defaultTitle =
    template === "project"
      ? "<h1>Project <br />Portfolio</h1>"
      : template === "blog"
      ? "<h1>News</h1>"
      : title;

  const defaultContent =
    template === "project"
      ? "Learn about our extensive experience providing rapid, accurate data and value-adding insights for clients from a diverse range of industries, right across Australia."
      : template === "blog"
      ? "Read about our experience providing rapid, accurate data and value-adding insights across the exploration, construction and production lifecycle. From feasibility to decommissioning. From planning to ongoing decision-making."
      : content;

  const defaultImage =
    carousel || carousel !== undefined
      ? carousel[0].image
      : template === "project"
      ? projectHeader
      : placeholderImage;

  return (
    // homepage has a higher banner
    // the green theme has a different background image
    <section
      className={`page-header layout ${homepage ? "page-header--home" : ""} ${
        theme === "secondary" ? "page-header--green" : ""
      }`}
      style={{
        "--header-theme": `var(--brand-${theme})`,
      }}
    >
      <div className="page-header__wrapper">
        <div className="col-content">
          <RenderShortcode content={defaultTitle} />
          <p className="content">
            <RenderShortcode content={defaultContent} />
          </p>
        </div>
        {carousel && carousel.length > 1 ? (
          <div className="col-image">
            <Carousel swiperSettings={swiperSettings}>
              {Object.keys(carousel).length > 0 &&
                carousel?.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <ImagePass src={item.image} className="main-image" />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        ) : (
          <div className="col-image">
            <ImagePass src={defaultImage} className="main-image" />
          </div>
        )}
      </div>
    </section>
  );
};
