import React from "react";
import "./ImageAndTextBlock.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import { Container } from "../../Container";

const ContentType = ({ data }) => {
  switch (data.contentType) {
    case "text":
      return (
        <div className="col col--text">
          <RenderContent content={data.content} />
        </div>
      );

    case "image":
      return (
        <div className={`col col--image ${data.squareAccent}`}>
          <div
            className="image-container"
            style={{
              "--image-position": `${data.imagePosition}`,
            }}
          >
            <ImagePass src={data.image} />
          </div>
        </div>
      );

    default:
      return <p>This type of content does not exist</p>;
  }
};

export const ImageAndTextBlock = ({ rows }) => {
  return (
    <Container>
      <section className="image-text-block layout">
        <div className="image-text-block--wrapper">
          {Object.keys(rows).length > 0 &&
            rows?.map((col, index) => {
              return <ContentType data={col} key={index} />;
            })}
        </div>
      </section>
    </Container>
  );
};
