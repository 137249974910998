import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RenderShortcode } from "../../RenderShortcode";
import { ImagePass } from "../../Image/ImagePass";
import GatsbyLink from "../../GatsbyLink";
import { GravityForm, gravityFormExtractor } from "../../GravityForm";
import { useAllGravityForms } from "../../../hooks";
import { Container } from "../../Container";
import "./FormBlock.scss";

const FormBlockComponent = ({
  content,
  image,
  formId,
  locations,
  officeLocations,
}) => {
  const gravityFormsData = useAllGravityForms();
  const formData = gravityFormExtractor(parseInt(formId), gravityFormsData);

  return (
    <>
      <Container>
        <section className="form-block layout">
          <div className="form-block__wrapper">
            <div className="col--text">
              <GravityForm
                formData={formData}
                dataLayerEvent={"form_submit_success"}
              />
              <RenderShortcode content={content} className="text-email" />
            </div>
            <div className="col--image">
              <div className="image-container">
                <ImagePass src={image} />
              </div>
            </div>
          </div>
        </section>
      </Container>
      {officeLocations && (
        <Container>
          <section className="office-locations layout">
            <div className="office-locations__wrapper">
              <h2>Office locations</h2>
              <div className="location-grid">
                {Object.keys(locations).length > 0 &&
                  locations?.map((location, index) => {
                    return (
                      <div key={index}>
                        <h3>{location.locationName}</h3>
                        <address>
                          {location.addressStreet}
                          <br />
                          {location.addressCity} {location.addressRegion}{" "}
                          {location.addressPostCode}
                        </address>
                        {location.addressPostal && (
                          <p>{location.addressPostal}</p>
                        )}
                        {location.phone && (
                          <p>
                            Phone:{" "}
                            <a
                              href={`tel:${location?.phone.replace(
                                /[^A-Z0-9]/gi,
                                ""
                              )}`}
                              className="location-grid__phone"
                            >
                              {location.phone}
                            </a>
                          </p>
                        )}
                        {location.faxNumber && <p>Fax: {location.faxNumber}</p>}
                        {location.googleMap && (
                          <a
                            href={location.googleMap}
                            className="button--blue"
                            rel="noreferrer"
                            target="_blank"
                          >
                            View in Maps
                          </a>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        </Container>
      )}
    </>
  );
};

export function FormBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                locationName
                addressStreet
                addressCity
                addressRegion
                addressPostCode
                addressPostal
                phone
                faxNumber
                googleMap
              }
            }
          }
        }
      `}
      render={(data) => (
        <FormBlockComponent
          locations={data.siteSettings.options.locations}
          {...props}
        />
      )}
    />
  );
}
