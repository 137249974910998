import React from "react";
import "./VideoBlock.scss";
import { Container } from "../../Container";
import ReactPlayer from "react-player/youtube";

export const VideoBlock = ({ videoUrl }) => {
  return (
    <Container>
      <section className="video-block layout">
        <div className="video-block-wrapper">
          <div className="player-wrapper">
            <ReactPlayer
              url={videoUrl}
              className="react-player"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </section>
    </Container>
  );
};
