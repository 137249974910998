import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Container } from "../../Container";
import "./TestimonialBlock.scss";
import { Carousel } from "../../Carousel";
import { RenderShortcode } from "../../RenderShortcode";

const TestimonialPass = ({
  title,
  data: {
    allWordpressWpTestimonials: { nodes: testimonials },
  },
}) => {
  const swiperSettings = {
    id: "testimonial-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: true,
    navigation: true,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <Container>
      <div className="layout testimonial-block">
        <div className="testimonial-block__wrapper">
          <RenderShortcode content={title} />
          <div className="testimonial-carousel">
            <Carousel swiperSettings={swiperSettings}>
              {Object.keys(testimonials).length > 0 &&
                testimonials?.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <RenderShortcode content={item.content} />
                      <strong>
                        <RenderShortcode content={item.acf.name} />
                      </strong>
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </div>
      </div>
    </Container>
  );
};

export const TestimonialBlock = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              acf {
                name
              }
            }
          }
        }
      `}
      render={(data) => <TestimonialPass {...props} data={data} />}
    />
  );
};
