import React from "react";

export const Text = (props) => {
  const { field, fieldId } = props;
  const { label, placeholder, isRequired, cssClass = "", id } = field;
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${cssClass ? ` ${cssClass}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{label}</label>
      <input
        name={gfName}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        type="text"
      />
    </div>
  );
};
