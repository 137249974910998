import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

//
// Component to be used as a wrapper for the fade-up effect
//

export const Container = ({ children }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
      {children}
    </div>
  );
};
