import React from "react";
import "./ListBlock.scss";

export const ListBlock = ({ heading, list, disclaimer }) => {
  return (
    <section className="list-block layout">
      <div className="list-block__wrapper">
        <h2 className="list-block__title">{heading}</h2>
        <ul className="list-block__list">
          {Object.keys(list).length > 0 &&
            list?.map((listItem, index) => {
              return <li key={index}>{listItem.listItem}</li>;
            })}
        </ul>
        {disclaimer && (
          <div className="list-block__disclaimer">
            <p>{disclaimer}</p>
          </div>
        )}
      </div>
    </section>
  );
};
