import React from "react";
import "./LogoFeed.scss";
import { ImagePass } from "../../Image/ImagePass";
import { Carousel } from "../../Carousel";
import { Container } from "../../Container";

export const LogoFeed = ({ logos, title }) => {
  const swiperSettings = {
    id: "logo-carousel",
    slidesPerView: 2,
    spaceBetween: 0,
    grabCursor: false,
    pagination: false,
    navigation: true,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      1080: {
        navgiation: false,
        slidesPerView: 3,
      },
      1340: {
        navgiation: false,
        slidesPerView: 6,
      },
    },
  };

  return (
    <Container>
      <section className="logo-feed layout">
        <div className="logo-feed__wrapper">
          <h2 className="heading">{title}</h2>
          <Carousel swiperSettings={swiperSettings}>
            {Object.keys(logos).length > 0 &&
              logos?.map((item, index) => {
                return item.link ? (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="logo-gallery__item"
                    key={index}
                  >
                    <img src={item?.logo?.source_url} />
                  </a>
                ) : (
                  <div className="logo-gallery__item" key={index}>
                    <img src={item?.logo?.source_url} />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section>
    </Container>
  );
};
