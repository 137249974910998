import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { RelatedPass } from "./RelatedPass";

const RelatedProjects = (props) => {
  const postType = props.contentType ? props.contentType : "post";
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          allWordpressWpProjects(
            sort: { fields: date, order: DESC }
            filter: { slug: { ne: "gatsby-demo" } }
          ) {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
        }
      `}
      render={(data) => <RelatedPass {...props} data={data} />}
    />
  );
};

export default RelatedProjects;
